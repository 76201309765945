/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import Lottie from "lottie-react"; // Import Lottie component from lottie-react
import animationData from "../otp.json";
import OTPInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import Google from "../images/google.svg";
import loginImage from "../images/login2.png";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { UserContext } from "../context/userContext";

export default function OtpModal({ isOpen, setIsOpen }) {
  // const { user,setUser } = useContext(UserContext); // Get setUser from context

  const [user, setUser] = useState()
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [otpSent, setOtpSent] = useState(false);
  // const [otp, setOtp] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otpDigits, setOtpDigits] = useState(""); // Array to hold each digit of OTP
  const [otpError, setOtpError] = useState("");
  const [emailOrPhoneNumber, setEmailOrPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [timer, setTimer] = useState(60); // Initial timer value in seconds

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpSent, timer]);

  const handleClick = () => {
    if (!emailOrPhoneNumber.trim()) {
      setError("Please enter a valid email or phone number");
    } else if (
      !isValidEmail(emailOrPhoneNumber) &&
      !isValidPhone(emailOrPhoneNumber)
    ) {
      setError("Please enter a valid email or phone number");
    } else {
      setOtpSent(true);
      setTimer(60); // Reset timer to 60 seconds when OTP is sent
    }
  };

  const closeModal = () => {
    setOpen(false);
  };
  const handleVerifyOTP = () => {
    const enteredOTP = ""; // Join the array into a single string
    if (enteredOTP === "1234") {
      console.log("OTP Verified!");
      alert("OTP Verified!");
      setIsOpen(false);
    } else {
      setOtpError("Invalid OTP. Please try again.");
    }

    if (otp2 === "1234") {
      alert("OTP Verified!");
      navigate("/dashboard");
      setIsOpen(false);
    }
  };

  const handleResendOTP = () => {
    setOtpSent(true);
    setTimer(60); // Reset timer to 60 seconds when OTP is resent
    setOtpDigits(["", "", "", ""]); // Reset OTP digits
    setOtpError(""); // Clear any previous OTP error messages
  };

  // const handleOTPChange = (index, value) => {
  //   // Update the specific OTP digit in the array
  //   const updatedOTP = [...otpDigits];
  //   updatedOTP[index] = value;
  //   setOtpDigits(updatedOTP);
  // };

  const isValidEmail = (value) => {
    // Basic email validation using regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  };

  const isValidPhone = (value) => {
    // Basic phone number validation using regex
    return /^[0-9]{10}$/.test(value);
  };

  // const handleChange = (e) => {
  //   const { value } = e.target;
  //   setEmailOrPhoneNumber(value);
  //   if (isValidEmail(value) || isValidPhone(value)) {
  //     setError("");
  //     setIsValid(true);
  //   } else {
  //     setIsValid(false);
  //   }
  // };


  console.log(user, "userOTPMODAL")

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data, "res.datares.data");
          setUser(res.data);
          setItems(res.data);
          toast.success("You are successfully logged in", {
            duration: 1000,
            position: "top-right",
          });
          setTimeout(() => {
            setIsOpen(false);
            return navigate("/");
          }, 1500);
        })
        .catch((err) => console.log(err));
    }
    return () => {
      setUser(null);
    };
  }, [navigate, user]);

  const [items, setItems] = useState([]);
  useEffect(() => {
    localStorage.setItem("users", JSON.stringify(items));
  }, [items]);
  console.log("items", items);
  return (
    <>
      <ToastContainer />
      <Transition show={open}>
        <Dialog className="relative " onClose={() => setIsOpen(false)}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000000bf]  z-[1000] transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-[10001] w-screen overflow-y-auto bgModal">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className=" relative transform overflow-hidden rounded-[1rem] bg-white text-left shadow-xl transition-all sm:my-8  sm:w-full sm:max-w-3xl ">
                  <button
                    onClick={closeModal}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-900"
                    aria-label="Close"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                  <div className="flex flex-col sm:flex-row ">
                    {/* left section */}

                    {!otpSent ? (
                      <div className="left-panel w-full md:w-1/2  bg-white py-10 sm:py-0 sm:p-10 flex flex-col justify-center ">
                        <div className="mx-5 sm:mx-0">
                          <p className=" text-xl sm:text-2xl font-bold">
                            Welcome To ScholarAi
                          </p>
                          <p className="text-sm my-2">
                            Student Login / Sign Up
                          </p>
                          <div>
                            <p className=" mt-10 sm:mt-5 mb-3 ">
                              Email Or Phone Number
                              <span className="text-red-700">*</span>
                            </p>
                            <form className="flex flex-col ">
                              <div className="border rounded-1xl bg-gray-100  inline-block rounded-lg  text-sm">
                                <div className="flex items-center md:justify-center p-2">
                                  <span className="inline-block p-1  text-gray-700 rounded-l-md ml-4">
                                    <FontAwesomeIcon icon={faEnvelope} />{" "}
                                  </span>
                                  <input
                                    type="text"
                                    className="flex-1 bg-transparent ml-4 focus:outline-none "
                                    placeholder="Email Or Phone Number"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={emailOrPhoneNumber}
                                    onChange={(e) =>
                                      setEmailOrPhoneNumber(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </form>

                            {error && (
                              <p className="text-red-500 text-sm mt-1">
                                {error}
                              </p>
                            )}
                            <div className="text-center py-5 sm:py-0">
                              <button
                                type="button"
                                className="  md:mt-5 fontMedium bg-[#f9943b] hover:bg-[#003060]  text-white font-semibold px-9 py-3 rounded-3xl inline-block"
                                onClick={handleClick}
                              // disabled={!isValid}
                              >
                                Send OTP
                              </button>
                            </div> 
                          </div>
                        </div>
                        <div className="mt-2 mb-10 sm:mb-0 flex flex-col justify-center items-center">
                          <p className="my-2">------------- Or -------------</p>
                          <div className="cursor-pointer flex gap-5 mt-2   rounded-md items-center justify-center w-52 p-2 bg-[#ffe6cd]">
                            <Link
                              href="https://www.facebook.com/"
                              target="_blank"
                            >
                              <img
                                src={Google}
                                alt="google"
                                width={25}
                                height={25}
                              />
                            </Link>
                            <p onClick={() => login()}>Sign in with Google</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="left-panel w-full  md:w-1/2  bg-white sm:p-10 flex flex-col justify-center ">
                        <p
                          className="underline text-lg cursor-pointer mb-12"
                          onClick={() => setOtpSent(false)} // Set otpSent to false to go back
                        >
                          Back
                        </p>

                        <div className="">
                          <div>
                            <p className="fontSemiBold  text-center mb-3  font-bold text-xl ">
                              Verify Your Account
                            </p>
                            <p className="text-center">
                              Sent a verification code to verify your email
                            </p>
                            <form className="flex flex-col">
                              {/* <div className="flex items-center justify-center gap-3 my-2">
                              {otpDigits.map((digit, index) => (
                                <input
                                  key={index}
                                  type="text"
                                  className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-purple-700  appearance-none rounded p-4 outline-none white   focus:ring-2 focus:ring-indigo-100"
                                  pattern="\d*"
                                  maxLength="1"
                                  value={digit}
                                  onChange={(e) =>
                                    handleOTPChange(index, e.target.value)
                                  }
                                />

                                
                              ))}
                            </div> */}
                              <div className="flex items-center justify-center gap-3 my-2">
                                <OTPInput
                                  value={otp2}
                                  onChange={setOtp2}
                                  numInputs={4}
                                  renderInput={(props) => (
                                    <input
                                      {...props}
                                      style={{
                                        width: "50px ",
                                        height: "50px",
                                        border: "1px solid #003060 ",
                                        borderRadius: "5px",
                                        margin: "10px",
                                        backgroundColor: "#f1f5f9",
                                        borderColor: "#003060",
                                        outline: "none",
                                        padding: "16px",
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              {otpError && (
                                <p className="text-red-500 text-sm mt-1">
                                  {otpError}
                                </p>
                              )}
                              <div className="text-center mt-5">
                                <button
                                  type="button"
                                  className="  bg-[#f9943b] hover:bg-[#003060]  text-white font-semibold px-9 py-3 rounded-3xl inline-block"
                                  onClick={handleVerifyOTP}
                                >
                                  Verify OTP
                                </button>
                                <p className="mt-1 text-gray-500">
                                  Enter demo otp 1234
                                </p>
                              </div>
                            </form>

                            <div className="text-center mt-5">
                              {timer > 0 ? (
                                <p className="text-gray-600">
                                  Resend OTP in {timer} seconds
                                </p>
                              ) : (
                                <button
                                  type="button"
                                  className="text-gray-500 hover:underline"
                                  onClick={handleResendOTP}
                                >
                                  Resend OTP
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* right section */}
                    <div className="right-panel w-full md:w-1/2 bg-white   bg-gradient-custom ">
                      {/* <div
                      className="hidden sm:flex justify-end cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <Image
                        src="/img/close.svg"
                        alt="close"
                        width={30}
                        height={30}
                        className="mt-[-26px]"
                      />
                    </div> */}

                      {!otpSent ? (
                        <div
                          class="  bg-[#e0e7ff] justify-center items-center flex-1 text-center   lg:flex rounded-tr-[10px] rounded-br-[10px]"
                          style={{ padding: "100px 0px" }}
                        >
                          <img
                            src={loginImage}
                            alt="login"
                            className="lg:w-full lg:h-auto"
                          />
                        </div>
                      ) : (
                        <div
                          class="  bg-[#e0e7ff] justify-center items-center flex-1 text-center   lg:flex rounded-tr-[10px] rounded-br-[10px]"
                          style={{ padding: "57px 0px" }}
                        >
                          <div className="flex justify-center items-center">
                            <Lottie
                              animationData={animationData}
                              loop
                              autoplay
                            // style={{ width: "50%", height: "50%" }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
