import React, { useState } from 'react'
import { EvalutionData } from "../../data/data";

export default function DetailedAnalysis() {
    const sections = ["Writing Test", "Listening Test"]
    const [currentSection, setCurrentSection] = useState(0);
    return (
        <section className='px-5 pb-10'>
            <div className='bg-white p-5 md:p-10 shadow-md rounded-md '>
                <h2 className="text-[#2A4563] text-2xl font-bold pb-2">
                    Detailed Analysis
                </h2>
                <p className="text-[#2A4563] text-sm py-1">Our IELTS-GPT system has been trained on millions of essays from 28 million students around the globe to ensure it can evaluate your IELTS writing with high accuracy as a professional IELTS examiner!</p>
                <div className='pt-5 flex flex-col md:flex-row justify-center items-start gap-5'>
                    <div className='md:w-[20%] flex flex-col gap-2'>
                        {sections.map((item, index) => (
                            <div onClick={() => setCurrentSection(index)} className='p-2 border rounded-md bg-white cursor-pointer'>{item}</div>
                        ))}
                    </div>
                    <div className="md:w-[80%] grid md:grid-cols-2 gap-5">
                        {EvalutionData.map((item) => (
                            <div className=" bg-[#F6F6F6] rounded-md p-5 relative ">
                                <p className="w-[50%] md:w-full mb-3 text-[#2A4563] text-lg font-medium">
                                    {item.title}
                                </p>
                                <div className="bg-white relative rounded-md p-3">
                                    <div
                                        className={`bg-primary-blue text-white absolute -top-10 z-10 right-5 rounded-t-[10px] p-2 ${item.classes}`}
                                    >
                                        {item.bandScore}
                                    </div>
                                    <p
                                        id="style-2"
                                        className="overflow-y-scroll h-32 text-[#2A4563] text-justify text-sm p-3 "
                                    >
                                        {item.description}
                                    </p>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
