import { BrowserRouter, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./page/Home";
import Examdetail from "./page/ExamDetails";
// import Login from "./page/Login";

import { GoogleOAuthProvider } from "@react-oauth/google";
// import { useContext } from "react";
import { UserProvider } from "./context/userContext";
import DashboardLayout from "./layout/DashboardLayout";
import DashboardHome from "./page/dashboard/DashboardHome";
import MockExams from "./page/dashboard/MockExams";
import Practice from "./page/dashboard/Practice";
import Reports from "./page/dashboard/Reports";
import Subscription from "./page/dashboard/Subscription";
import AIRanking from "./page/dashboard/AI_Ranking";
import PerformanceAnalytics from "./page/dashboard/Performance_Analytics";
import MyProfile from "./page/dashboard/My_Profile";

const clientId =
  "483619648597-giknf43p085748h88hjebe5f7vm3be42.apps.googleusercontent.com";

export default function App() {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <UserProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="home" element={<Home />} />
            {/* <Route path="login" element={<Login />} /> */}
            <Route path="exam/:id" element={<Examdetail />} />

            {/* <Route path="dashboard" element={<Dashboard />} ></Route> */}
            <Route path="dashboard" element={<DashboardLayout />}>
              <Route index element={<DashboardHome />} />
              <Route path="mockExams" element={<MockExams />} />
              <Route path="practice" element={<Practice />} />
              <Route path="aiRanking" element={<AIRanking />} />
              <Route
                path="performanceAnalytics"
                element={<PerformanceAnalytics />}
              />
              <Route path="myProfile" element={<MyProfile />} />
              <Route path="Reports" element={<Reports />} />
              <Route path="subscription" element={<Subscription />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </UserProvider>
    </GoogleOAuthProvider>
  );
}
