import React from 'react'
import { PerformanceSummaryData } from '../../data/data'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function PerformanceSummary() {
    return (
        <section className='px-5 pb-10'>
            <div className="bg-white p-5 md:p-10 shadow-md rounded-md flex flex-col md:flex-row items-center justify-between"
            >
                <div className="md:w-[30%]">
                    <h2 className="text-[#2A4563] text-2xl font-bold pb-2">
                        Overall Performance Summary
                    </h2>
                    <p className="text-[#2A4563] text-sm py-1">Our IELTS-GPT system has been trained on millions of essays from 28 million students around the globe to ensure it can evaluate your IELTS writing with high accuracy as a professional IELTS examiner!</p>
                </div>
                <div className="hidden md:w-[70%] md:flex items-start justify-center">
                    {PerformanceSummaryData.map((item) => (
                        <div className={`flex flex-col items-center justify-between p-3 text-center gap-2 w-1/4`}>
                            <CircularProgressbar value={item.score} text={item.value} className='h-24 w-auto' />
                            <div className='font-semibold text-sm'>{item.title}</div>
                            <p className='text-xs'>{item.desc}</p>
                        </div>
                    ))}
                </div>
                <div className='grid grid-cols-2 md:hidden'>
                    {PerformanceSummaryData.map((item) => (
                        <div className={`flex flex-col items-center justify-between p-3 text-center gap-2 md:w-1/4`}>
                            <CircularProgressbar value={item.score} text={item.value} className='h-24 w-auto' />
                            <div className='font-semibold text-sm'>{item.title}</div>
                            <p className='text-xs'>{item.desc}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
