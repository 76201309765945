// import ProgressBar from "@ramonak/react-progress-bar";
// import Button from "../../components/ResubaleComponents/Button";
// import leftArrow from "../../assets/images/leftArrow.png";
 


// const Practice = () => {
//   return (
//     <>
//       <div
//         className="bg-white rounded-lg mx-4 md:mx-auto max-w-[900px] border-2 border-[#E4F9FF] my-8 "
//         style={{ boxShadow: "0.2px 0px 4px 4px rgb(122 219 249)" }}
//       >
//         <div className="flex justify-end px-8 pt-2">Total Questions : 50 </div>
//         <div className="px-8 py-6 md:px-10">
//           {/* <ProgressBar completed={50} className="rounded-lg" /> */}
//           <ProgressBar
//             completed={10}
//             bgColor="#0AA6D7"
//             transitionDuration="1s"
//             animateOnRender={true}
//             height="13px"
//             labelSize="10px"
//           />
//           {/* // customLabelStyles  */}

//           <div className=" ">
//             <div className="flex justify-between py-5 items-center">
//               <h2>Questions : 1</h2>
//             </div>
//             <p>
//               lorem Ipsum is simply dummy text of the printing and typesetting
//               industry. Lorem Ipsum has been the industrys stand?
//             </p>
//           </div>
//           <div className="flex justify-center sm:justify-start">
//             <textarea
//               className="w-[900px] my-10 appearance-none text-md py-1 px-2 focus:outline-none border-2 rounded-lg border-[#E4F9FF] focus:ring-blue-600 focus:border-[#0AA6D7]    text-black placeholder-blue-300 dark:placeholder-gray-600   "
//               type="search"
//               name="q"
//               placeholder="Answer :"
//             />
//           </div>

//           <div className="flex justify-between items-center">
//             <div className="relative">
//               <img
//                 src={leftArrow}
//                 className="w-6 h-6 absolute bottom-1.5 ml-1.5"
//                 alt="leftarrow"
//               />
//               <Button
//                 label="Prev"
//                 type="submit"
//                 className="bg-white border-2 border-[#E4F9FF] text-[#0AA6D7]"
//               />
//             </div>

//             <button className="bg-[#0AA6D7] text-white px-4 py-1 rounded-lg">
//               Submit
//             </button>
//             <div className="relative">
//               <Button
//                 label="Next"
//                 type="submit"
//                 // className="bg-[#0AA6D7]  text-white"
//                 className="bg-white border-2 border-[#E4F9FF] text-[#0AA6D7]"
//               />
//               <img
//                 src={leftArrow}
//                 className="w-6 rotate-180 h-6 absolute bottom-1 ml-1.5 right-1"
//                 alt="right arrow"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Practice;





import React, { useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import Button from "../../components/ResubaleComponents/Button";
import leftArrow from "../../assets/images/leftArrow.png";

const questions = [
  {
    id: 1,
    text: "lorem Ipsum is simply dummy text of the printing and typesetting industry ?",
  },
  {
    id: 2,
    text: "What is the capital of France?",
  },
  {
    id: 3,
    text: "What is the capital of india?",
  },
  {
    id: 4,
    text: "What is the capital of china?",
  },
  {
    id: 5,
    text: "What is the capital of nepal?",
  },
  {
    id: 6,
    text: "What is the capital of thailand?",
  },
  // ... Add your 48 other questions here
];

const Practice = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});

  const handleInputChange = (event) => {
    setUserAnswers({
      ...userAnswers,
      [currentQuestion]: event.target.value,
    });
  };

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleSubmit = () => {
    // Process userAnswers (e.g., send to server for grading)
    console.log("Submitted Answers:", userAnswers);
  };

  const progress = Math.round(((currentQuestion + 1) / questions.length) * 100);

  return (
    <div
      className="bg-white rounded-lg mx-4 md:mx-auto max-w-[900px] border-2 border-[#E4F9FF] my-8 "
      style={{ boxShadow: "0.2px 0px 4px 4px rgb(122 219 249)" }}
    >
      <div className="flex justify-end px-8 pt-2">
        Total Questions : {questions.length}
      </div>
      <div className="px-8 py-6 md:px-10">
        <ProgressBar
          completed={progress}
          bgColor="#0AA6D7"
          transitionDuration="1s"
          animateOnRender={true}
          height="13px"
          labelSize="10px"
        />

        <div className=" ">
          <div className="flex justify-between py-5 items-center">
            <h2>Question: {currentQuestion + 1}</h2>
          </div>
          {/* {console.log(questions[currentQuestion], "questions")} */}
          <p>{questions[currentQuestion].text}</p>
        </div>

        <div className="flex justify-center sm:justify-start">
          <textarea
            className="w-[900px] my-10 appearance-none text-md py-1 px-2 focus:outline-none border-2 rounded-lg border-[#E4F9FF] focus:ring-blue-600 focus:border-[#0AA6D7]    text-black placeholder-blue-300 dark:placeholder-gray-600   "
            type="search"
            name="q"
            placeholder="Answer :"
            value={userAnswers[currentQuestion] || ""}
            onChange={handleInputChange}
          />
          {/* {console.log(
            userAnswers[currentQuestion],
            "userAnswers[currentQuestion] userAnswers[currentQuestion] "
          )} */}
        </div>

        <div className="flex justify-between items-center">
          <div className="relative">
            {currentQuestion ? (
              <>
                <img
                  src={leftArrow}
                  className="w-6 h-6 absolute bottom-1.5 ml-1.5"
                  alt="leftarrow"
                />

                <Button
                  label="Prev"
                  type="button"
                  className="bg-white border-2 border-[#E4F9FF] text-[#0AA6D7]"
                  onClick={handlePrevQuestion}
                  disabled={currentQuestion === 0}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <button
            className="bg-[#0AA6D7] text-white px-4 py-1 rounded-lg"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <div className="relative">
            <Button
              label="Next"
              type="button"
              className="bg-white border-2 border-[#E4F9FF] text-[#0AA6D7]"
              onClick={handleNextQuestion}
              disabled={currentQuestion === questions.length - 1}
            />
            <img
              src={leftArrow}
              className="w-6 rotate-180 h-6 absolute bottom-1 ml-1.5 right-1"
              alt="right arrow"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Practice;