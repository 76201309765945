// sidebarData.js
import HomeIcon from "../assets/images/DashboardLayout.png"; // Import your icons here
import Practice from "../assets/images/practice.png"; // Import your icons here
import Profile from "../assets/images/profile.png"; // Import your icons here
import Ranking from "../assets/images/ranking.png"; // Import your icons here
import Reports from "../assets/images/reports.png"; // Import your icons here
import Performance from "../assets/images/performance.png"; // Import your icons here
import Subscription from "../assets/images/subscription.png"; // Import your icons here
import MockExams from "../assets/images/mock_exam.png"; // Import your icons here

export const sidebarLinks = [
  {
    label: "Home",
    path: "/dashboard",
    icon: HomeIcon,
  },
  {
    label: "Mock Exams",
    path: "/dashboard/mockExams",
    icon: MockExams,
  },
  {
    label: "Practice",
    path: "/dashboard/practice",
    icon: Practice,
  },

  {
    label: "AI Ranking",
    path: "/dashboard/aiRanking",
    icon: Ranking,
  },

  {
    label: "Performance Analytics",
    path: "/dashboard/performanceAnalytics",
    icon: Performance,
  },
  {
    label: "My Profile",
    path: "/dashboard/myProfile",
    icon: Profile,
  },
  {
    label: "Reports",
    path: "/dashboard/Reports",
    icon: Reports,
  },
  {
    label: "Subscriptions",
    path: "/dashboard/subscription",
    icon: Subscription,
  },
];
