import React from 'react'
import heroImage from "../../images/hero_image.png"
import heroBg from "../../images/hero-bg.png";
import Button from '../ui/Button'

export default function HeroSection() {
    return (
        <section className="mt-[55px]">
            <div className='hidden md:block w-full h-[30px] bg-primary-blue ' />
            <div className='bg-primary-blue'>
                <div className='container mx-auto p-web flex-col flex md:flex-row gap-5'>
                    <div className='md:w-1/2'>
                        <img src={heroImage} alt="heroImage" />
                    </div>
                    <div className='md:w-1/2 text-white text-center md:text-left font-bold text-4xl lg:text-6xl md:leading-snug'>
                        AI-powered Subjective Answer Analysis for Students
                        <br />
                        <Button variant={"orange"} text="Get a sample" />
                    </div>
                </div>
            </div>
            <img src={heroBg} alt="hero-bg" className='w-full' />

            {/* <p>
                Scholar.AI - AI-powered Subjective Answer Analysis for
                Students
                Improve your subjective answers and prepare for competitive
                exams better.
            </p> */}
        </section >
    )
}
