// import { Link } from "react-router-dom";
import search from "../../assets/images/search.png";
import arrowDown from "../../assets/images/arrow_down.png";
import bell from "../../assets/images/bell.png";

const Header = (props) => {
  return (
    <header className="shadow-md sticky top-0 z-[1] flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
      <div className="flex flex-grow items-center justify-between  shadow-2  2xl:px-11 w-full">
        <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 18L20 18"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M4 12L20 12"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M4 6L20 6"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </button>
        </div>
        <p className="text-2xl font-medium hidden md:block">Dashboard</p>

        <div className="hidden md:block">
          <div className="flex items-center bg-[#F9FAFB] rounded-lg p-2 ">
            <img
              src={search}
              className="w-5 h-5 text-gray-500 mr-2"
              alt="search"
            />

            <input
              type="text"
              placeholder="Search here....."
              className="outline-none bg-[#F9FAFB] w-full text-gray-700"
            />
          </div>
        </div>

        <div className="flex items-center gap-3 2xsm:gap-7">
          <a
            href="/"
            className="flex justify-center items-center w-10 h-10 rounded-lg text-gray-600 bg-[#E4F9FF] hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300"
          >
            <img src={bell} alt="bell" className="w-4 h-4" />
          </a>

          <div className="flex items-center space-x-4 mr-4">
            {/* <!-- User Area --> */}
            <img
              className="inline-block w-10 h-10 rounded-lg ring-2 ring-white"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />

            <a href="/" className="text-black  ">
              <p>Saurabh</p>
              <p className="text-sm">User</p>
            </a>

            <a
              href="/"
              className="flex items-center text-gray-600 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300"
            >
              <img src={arrowDown} alt="arrow down" className="w-3 h-3" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
