import React from 'react'
import SummaryTable from "./../SummaryTable";

export default function SectionalSummary() {
    return (
        <section className='px-5 pb-10'>
            <div className="bg-white md:p-10 shadow-md rounded-md">
                <SummaryTable />
            </div>
        </section>
    )
}
