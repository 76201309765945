import React, { useRef } from 'react'
import Slider from "react-slick";
import CourseCard from '../ui/CourseCard'
import course from "../../images/courses.jpg"
import person from "../../images/person.jpg"
import "./courses.css";
const Blob = ({ rotate, scale, position }) => {
    return (
        <div className={` ${rotate} ${scale} ${position} absolute z-10 h-[150px] w-[150px] rounded-full bg-gradient-to-tr from-primary-orange via-primary-orange to-transparent`} />
    )
}

const Arrow = ({ rotate, scale, position }) => (
    <div className={`${rotate} ${scale} ${position}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={20} width={20}><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" fill='#F9943B' /></svg>
    </div>
)

export default function Courses() {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const slider = useRef(null);
    const data = [1, 2, 3, 4, 5, 6, 7]
    const settings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 2,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 768, // Adjust the width as needed
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024, // Optionally adjust for tablet
                settings: {
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1368, // Optionally adjust for tablet
                settings: {
                    dots: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
        ],
        beforeChange: (current, next) => setCurrentSlide(next),
        appendDots: (dots) => {
            return (
                <div id="courses-nav" className='absolute top-0 rounded-lg flex flex-row items-center justify-center'>
                    <div
                        className="hover:cursor-pointer"
                        onClick={() => slider?.current?.slickPrev()}
                    >
                        <Arrow rotate="rotate-180" />
                    </div>
                    <ul id="coursesDots" className='m-0 hidden md:block'>{dots}</ul>
                    <div
                        className="hover:cursor-pointer"
                        onClick={() => slider?.current?.slickNext()}
                    >
                        <Arrow rotate={""} />
                    </div>
                </div>
            )
        }
    };

    return (
        <section className='container mx-auto md:px-[100px]'>
            <div className='rounded-2xl p-web md:shadow-2xl relative overflow-hidden'>
                <div className='text-5xl md:text-6xl z-20 font-semibold pb-5'>
                    FEATURED COURSES
                </div>
                <p className='text-base'>
                    Scholar.AI is an AI-powered subjective answer analysis tool that can help students to improve their subjective answers and prepare for competitive exams better.
                </p>
                <div className='pt-10'>
                    <Slider {...settings} ref={slider} className='bg-white z-20 rounded-2xl px-4 pb-10 shadow-courses'>
                        {data.map((item) => (
                            <CourseCard key={data} courseImage={course} authorImage={person} authorName={"Jane Doe"} authorRole={"Software Developer"} courseDescription="Information about UI/UX design" rating={3} timing={"3 h 20 min"} />
                        ))}
                    </Slider>
                </div>
                <Blob rotate={"rotate-45"} scale="scale-[1.8]" position={"invisible lg:visible -top-[120px] right-10"} />
                <Blob rotate={"rotate-90"} scale="scale-[1.5]" position={"hidden md:block -bottom-20 left-0"} />
                <Blob rotate={"rotate-180"} scale="scale-[1.1]" position={"hidden md:block -bottom-10 right-0"} />
            </div>

        </section>
    )
}
