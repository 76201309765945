import React from 'react'
import { strengthsAndWeaknesses } from '../../data/data'

export default function StrengthAndWeakness() {
    return (
        <section className='px-5 pb-10'>
            <h2 className="text-[#2A4563] text-2xl font-bold p-5 md:px-10 w-full">
                Strengths and weaknesses
            </h2>
            <div className='flex flex-col md:flex-row justify-between'>
                <div className='md:w-[47%] p-10 mb-5 md:mb-0 bg-white rounded-lg shadow-md'>
                    <div className='font-medium text-xl text-center pb-3'>Strengths</div>
                    <p>{strengthsAndWeaknesses.strengths}</p>
                </div>
                <div className='md:w-[47%] p-10 bg-white rounded-lg shadow-md'>
                    <div className='font-medium text-xl text-center pb-3'>Weaknesses</div>
                    <p>{strengthsAndWeaknesses.weaknesses}</p>
                </div>
            </div>
        </section>
    )
}
