import React from 'react'
import counterImage from "../../images/counter.png";
import CountUp from 'react-countup';

export default function Counter() {
    return (
        <section className=''>
            <img src={counterImage} alt="counterImage" className='hidden md:block w-full scale-y-[-1] translate-y-[1px]' />
            <div className='md:bg-primary-blue'>
                <div className='bg-white container mx-auto md:bg-primary-blue md:p-[80px] px-[120px] flex items-center justify-center'>
                    <div className='bg-white md:rounded-3xl md:p-web flex flex-col md:flex-row justify-center h-[100vh] gap-5'>
                        <div className='md:w-1/2 flex flex-col justify-center items-center'>
                            <div className='text-5xl md:text-6xl font-semibold pb-5'>
                                OVER 4000+ COMPLETED COURSES
                            </div>
                            <p className='text-base'>
                                Scholar.AI is an AI-powered subjective answer analysis tool that can help students to improve their subjective answers and prepare for competitive exams better.
                            </p>
                        </div>
                        <div className='md:w-1/2 pt-20 md:pt-0 flex flex-col justify-center items-center rel ative'>
                            <div className='w-[220px] h-[220px] md:w-[300px] md:h-[300px] mx-auto bg-primary-orange rounded-full relative'>
                                <div className='text-white flex items-center justify-center mx-auto w-1/2 h-full text-2xl md:text-3xl font-bold text-center'>2000 + Happy users</div>

                                <div className='bg-white shadow-counter rounded-full p-5 h-[80px] w-[80px] md:h-[100px] md:w-[100px] absolute -top-10 left-1/2 flex flex-col items-center justify-center text-sm'><div className='font-bold text-xl text-primary-blue'>
                                    <CountUp start={0} end={7} duration={5} />
                                </div> Products</div>
                                <div className='bg-white shadow-counter rounded-full p-5 h-[90px] w-[90px] md:h-[110px] md:w-[110px] absolute bottom-10 -left-10 flex flex-col items-center justify-center text-sm'><div className='font-bold text-xl text-primary-blue'><CountUp start={0} end={150} duration={5} />+</div> Products</div>
                                <div className='bg-white shadow-counter rounded-full p-5 h-[100px] w-[100px] md:h-[130px] md:w-[130px] absolute top-2/3 right-0 flex flex-col items-center justify-center text-sm'><div className='font-bold text-xl text-primary-blue'><CountUp start={0} end={2000} duration={5} />+</div> Products</div>
                                <div className='absolute bg-primary-blue h-[30px] w-[30px] rounded-full top-0 left-0' />
                                <div className='absolute bg-primary-blue h-[30px] w-[30px] rounded-full -bottom-10 left-1/3' />
                                <div className='absolute bg-primary-blue h-[30px] w-[30px] rounded-full top-1/2 -right-10' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img src={counterImage} alt="counterImage" className='w-full hidden md:block' />
        </section>
    )
}
