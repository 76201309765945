// data.js
const dataCard = [
  {
    title: "Total Students Available",
    number: 1220,
    iconPath:
      "M8 17l4 4 4-4m-4-5v9 M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29",
    bgColor: "#e7e6fb",
  },
  {
    title: "Total Courses Available",
    number: 45,
    iconPath:
      "M8 17l4 4 4-4m-4-5v9 M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29",
    bgColor: "#f9e5ea",
  },
  {
    title: "Active Enrollments",
    number: 300,
    iconPath:
      "M8 17l4 4 4-4m-4-5v9 M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29",
    bgColor: "#dfedf7",
  },
  {
    title: "New Registrations",
    number: 150,
    iconPath:
      "M8 17l4 4 4-4m-4-5v9 M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29",
    bgColor: "#fbedd9",
  },
];

export default dataCard;

export const PerformanceSummaryData = [
  {
    img: require("../assets/images/score.png"),
    score: `${78}`, // in percentage
    value: "78%",
    title: "Score",
    desc: "Total score achieved",
  },
  {
    img: require("../assets/images/attempted.png"),
    score: `${(20 / 25) * 100}`,
    value: "20",
    title: "Attempted Questions",
    desc: "Number of questions the student has attempted",
  },
  {
    img: require("../assets/images/accuracy.png"),
    score: 68,
    value: "68%",
    title: "Accuracy",
    desc: "Percentage of correct answers",
  },
  {
    img: require("../assets/images/score.png"),
    score: 89,
    value: "89",
    title: "Bands",
    desc: "For excams like IELTS, show the band score",
  },
];

export const strengthsAndWeaknesses = {
  strengths:
    "Our IELTS-GPT system has been  trained on millions of essays  from  28 million students around the globe  to ensure it can evaluate your IELTS writing with  high accuracy as a professional IELTS. Our IELTS-GPT system has been  trained on millions of essays  from  28 million students around the globe  to ensure it can evaluate your IELTS writing with  high accuracy as a professional IELTS. ",
  weaknesses:
    "Our IELTS-GPT system has been  trained on millions of essays  from  28 million students around the globe  to ensure it can evaluate your IELTS writing with  high accuracy as a professional IELTS. Our IELTS-GPT system has been  trained on millions of essays  from  28 million students around the globe  to ensure it can evaluate your IELTS writing with  high accuracy as a professional IELTS. Our IELTS-GPT system has been  trained on millions of essays  from  28 million students around the globe  to ensure it can evaluate your IELTS writing with  high accuracy as a professional IELTS.",
};

export const AreasToImproveData = [
  {
    heading: "Ask AI for suggestions",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, industry's standard dummy text ever since the 1500s",
  },
  {
    heading: "Improvements in Solutions",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, industry's standard dummy text ever since the 1500s",
  },
  {
    heading: "AI - sample answer",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, industry's standard dummy text ever since the 1500s",
  },
];

export const EvalutionData = [
  {
    title: "Task Acheivement",
    bandScore: "7.0",
    classes: "bg-[#EBD7FF] border-[#9A34FF]",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, industry's standard dummy text ever since the 1500s",
  },
  {
    title: "Coherence and Cohesion",
    bandScore: "6.0",
    classes: "bg-[#DEFDBF] border-[#9BFF37]",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, industry's standard dummy text ever since the 1500s",
  },
  {
    title: "Lexical Resource",
    bandScore: "5.0",
    classes: "bg-[#FED0D4] border-[#FE3244]",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, industry's standard dummy text ever since the 1500s",
  },
  {
    title: "Grammatical Range And Accuracy",
    bandScore: "8.0",
    classes: "bg-[#F8F88C] border-[#C7C706]",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, industry's standard dummy text ever since the 1500s",
  },
];

export const SummaryTableData = [
  {
    section_name: "General Intelligence And Reasoning",
    score: "-4.5/50",
    score_value: -4.5 / 50,
    attempted: "9/25",
    attempted_value: 9 / 25,
    accuracy: "0%",
    accuracy_value: 0,
    time: "00:51 / 60 min",
    time_value: 51 / 3600,
    ip_address: "205.255.209.215",
    status: true,
  },
  {
    section_name: "General Awareness",
    score: "0/50",
    score_value: 0 / 50,
    attempted: "0/25",
    attempted_value: 0 / 25,
    accuracy: "0%",
    accuracy_value: 0,
    time: "00:01 / 60 min",
    time_value: 1 / 3600,
    ip_address: "34.159.101.181",
    status: true,
  },
  {
    section_name: "Quantitative Apitude",
    score: "0/50",
    score_value: 0 / 50,
    attempted: "13/25",
    attempted_value: 13 / 25,
    accuracy: "0%",
    accuracy_value: 0,
    time: "00:01 / 60 min",
    time_value: 1 / 3600,
    ip_address: "184.139.248.150",
    status: true,
  },
  {
    section_name: "English Comprehension",
    score: "34/100",
    score_value: 34 / 100,
    attempted: "25/25",
    attempted_value: 25 / 25,
    accuracy: "24%",
    accuracy_value: 24 / 100,
    time: "01:30 / 60 min",
    time_value: 90 / 3600,
    ip_address: "206.48.254.74",
    status: true,
  },
];
