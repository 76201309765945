import "@fortawesome/fontawesome-free/css/all.css";
import SectionalSummary from "../../components/analytics/SectionalSummary";
import PerformanceSummary from "../../components/analytics/PerformanceSummary";
import StrengthAndWeakness from "../../components/analytics/StrengthAndWeakness";
import AreasToImprove from "../../components/analytics/AreasToImprove";
import DetailedAnalysis from "../../components/analytics/DetailedAnalysis";
import QuestionDistribution from "../../components/analytics/QuestionDistribution";
import TestSummary from "../../components/analytics/TestSummary";

const PerformanceAnalytics = () => {
  return (
    <>
      <TestSummary />
      <PerformanceSummary />
      <SectionalSummary />
      <DetailedAnalysis />
      <StrengthAndWeakness />
      <AreasToImprove />
      <QuestionDistribution />
    </>
  );
};

export default PerformanceAnalytics;

//  <div className="py-24 ">
//         <div className="border border-[#BDC5CF] max-w-screen-2xl rounded-t-[20px] bg-[#E9EDF4]">
//           <div className="bg-[#0AA6D7] text-white font-bold text-2xl px-8 rounded-t-[20px]">
//             Evalutation
//           </div>

//           <div className="flex flex-wrap justify-center items-center gap-10 py-14 ">
//             {EvalutionData.map((item) => (
//               <div className="w-[430px] bg-white rounded-2xl">
//                 <div className="p-6 relative ">
//                   <p className="mb-6 text-[#2A4563] text-xl font-medium px-2">
//                     {item.title}
//                   </p>
//                   <div
//                     className={`border absolute top-0 right-0 rounded-tr-[17px] p-3 rounded-bl-[5px] ${item.classes}`}
//                   >
//                     {item.bandScore}
//                   </div>
//                   <div className="  h-52  bg-[#F6F6F6] rounded-2xl p-3">
//                     <p
//                       id="style-2"
//                       className="overflow-y-scroll h-44 text-[#2A4563] text-justify p-3"
//                     >
//                       {item.description}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
