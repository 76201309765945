import React, { useState } from 'react'
import Rating from "react-rating";
import yellowStar from "../../assets/images/starYellow.png";
import grayStar from "../../assets/images/starGray.png";

export default function TestSummary() {
    const [rating, setRating] = useState(0);

    const handleRating = (rate) => {
        setRating(rate);
    };
    return (
        <section className='px-5 pb-10'>
            <div className="bg-white p-5 md:p-10 shadow-md rounded-md flex flex-col md:flex-row items-center justify-between"
            >
                <div className="md:w-2/5">
                    <h2 className="text-[#2A4563] text-2xl font-bold pb-2">
                        {" "}
                        IELTS Test Summary{" "}
                    </h2>
                    <p className="text-[#2A4563] text-sm py-1">Our IELTS-GPT system has been trained on millions of essays from 28 million students around the globe to ensure it can evaluate your IELTS writing with high accuracy as a professional IELTS examiner!</p>
                </div>
                <div className="md:w-2/5 my-4 flex flex-col items-center justify-center">
                    <h2 className="text-[#2A4563]  text-xl font-bold pb-2 text-center">
                        Rate This Test{" "}
                    </h2>
                    <Rating
                        initialRating={rating}
                        emptySymbol={<img src={grayStar} alt="grayStar" className='h-7 w-auto mx-0.5' />}
                        fullSymbol={<img src={yellowStar} alt="yellowStar" className='h-7 w-auto mx-0.5' />}
                        onChange={(rate) => handleRating(rate)}
                    />
                </div>
            </div>
        </section>
    )
}
