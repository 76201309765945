import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const SummaryBarChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [
          "Section 1",
          "Section 2",
          "Section 3",
          "Section 4",
        ],
        datasets: [
          {
            label: "Score",
            data: [4, 1, 12, 2],
            backgroundColor: [
              //  "rgba(255, 99, 132, 0.2)",
              "#E948AE",
            ],

            borderWidth: 1,
            // borderRadius: {
            //   topLeft: 10,
            //   topRight: 10, // Rounded top corners
            //   bottomLeft: 0,
            //   bottomRight: 0, // No rounding at the bottom
            // },
          },
          {
            label: "Attempted", // New dataset
            data: [4, 8, 6, 2], // Data for the new bars
            backgroundColor: ["#10C45C"],

            borderWidth: 1,
          },
          {
            label: "Accuracy", // Third dataset
            data: [5, 10, 6, 7],
            backgroundColor: ["#F9BD44"],

            borderWidth: 1,
          },
          {
            label: "Time", // Fourth dataset
            data: [8, 8, 5, 4],
            backgroundColor: ["#1EC1D9"],

            borderWidth: 1,
          },
        ],
      },
      options: {
        indexAxis: "x", // This option makes the chart vertical
        scales: {
          x: {
            grid: {
              display: false, // Disable grid lines for the x-axis
            },
            title: {
              display: true,
              text: "Sections", // Label for x-axis
              font: {
                size: 14,
              },
            },
          },
          y: {
            grid: {
              display: false, // Disable grid lines for the y-axis
            },
            title: {
              display: true,
              text: "Number of questions", // Label for x-axis
              font: {
                size: 14,
              },
            },
            beginAtZero: true,
          },
        },

        barThickness: 30, // Adjust bar width (in pixels)
        categoryPercentage: 0.5,
      },
      responsive: true, // Adjust spacing between bars (0.8 = 80% of the bar space, 20% for spacing)
      maintainAspectRatio: false, // Allow custom aspect ratio

    });

    return () => {
      chart.destroy();
    };
  }, []);

  return (
    <>
      <div class="main-container">
        <div class="container mx-auto">
          {/* <canvas ref={chartRef} width={600} height={200} id="chart-1"></canvas> */}
          <canvas ref={chartRef} id="chart-1"></canvas>
        </div>
      </div>
    </>
  );
};

export default SummaryBarChart;
