import React, { useState } from 'react'

import { AreasToImproveData } from '../../data/data'

export default function AreasToImprove() {
    const [currentSlide, setCurrentSlide] = useState(0);
    return (
        <section className='px-5 pb-10'>
            <div className="bg-white p-5 md:p-10 shadow-md rounded-md">
                <h2 className="text-[#2A4563] text-2xl font-bold w-full pb-5">
                    Areas to Improve
                </h2>
                <div className='flex flex-col md:flex-row justify-between items-start'>
                    <div className='md:w-[27%] flex flex-col gap-2 justify-start'>
                        {AreasToImproveData.map((item, index) => (
                            <div key={`areatoimprove${index}`} className={`${index === currentSlide ? "bg-primary-blue text-white" : "text-primary-blue"} p-2 border rounded-md cursor-pointer`} onClick={() => setCurrentSlide(index)}>
                                {item.heading}
                            </div>
                        ))}
                    </div>
                    <div className='md:w-[70%] pt-7 md:p-7 md:shadow-md rounded-md'>
                        <div className='font-semibold text-2xl pb-3 text-primary-blue'>{AreasToImproveData[currentSlide].heading}</div>
                        <p className=''>{AreasToImproveData[currentSlide].text}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
