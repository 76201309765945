/* eslint-disable react/prop-types */

const Button = ({ label, onClick, type = "button", className = "" }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`px-7 py-1 rounded-lg  ${className}`}
    >
      {label}
    </button>
  );
};

export default Button;
